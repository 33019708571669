import React from 'react';

export default function Home(){
    return(
        <div className='section home'>
            <div className='menu'>
                <div className='valign-wrapper'>
                    <div className='row menu-bar'>
                        <div className='col s12 m2'>
                            <a className='brand'>Airion</a>
                        </div>
                        <div className='col s12 m7'>
                            <div className='tab-con center'>
                                <a className='tab'>Home</a>
                                <a className='tab'>About</a>
                                <a className='tab'>Gallery</a>
                                <a className='tab'>Pricing</a>
                            </div>
                        </div>
                        <div className='col s12 m3'>
                            <div className='tab-chip'>Vendors Registration
                                <i className='material-icons left purple-text'>mail</i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='main'>
                <div className='row container_main'>
                    <div className='col s12 m5'>
                        <div className='headline-con'>
                            <h1 className='headline'>Feels Are Captured in Every Way.</h1>
                            <button className='btn btn-flat black started white-text'>Get Started</button>
                        </div>
                    </div>
                    <div className='col s12 m4'>
                        <div className='main-pic-con center'>
                            <img src={require('../images/005.jpg')} className='pic'/>
                        </div>
                    </div>
                    <div className='col s12 m3'>
                        <i className='material-icons red white-text circle pad-10'>favorite</i>
                       <h5 className='small-headline'>Make Moments Memorable</h5> 
                        <div className='main-pic-con-circle'>
                            <a className='black-text showreel'>Showreel</a>
                            <img src={require('../images/006.jpg')} className='pic-circle'/>
                            <i className='material-icons medium left play-icon'>play_circle_filled</i>
                        </div>
                    </div>
                </div>
                <div className='section cremem'>
                    <div className='container_box'>
                        <div className='transparent box valign-wrapper'>
                            <div className='box-over center'>
                                <h5 className='headlines_box'>Weddings</h5>
                                <img src={require('../images/abcc.png')} className='pic-circle'/>
                                <button className='btn btn-flat black white-text btn-box'>Book Venues</button>                           
                            </div>
                            <div className='box-over center'>
                                <h5 className='headlines_box'>Coroprate Events</h5>
                                <img src={require('../images/xyzz.png')} className='pic-circle'/>
                                <button className='btn btn-flat black white-text btn-box'>Book Venues</button>                           
                            </div>
                            <div className='box-over center'>
                                <h5 className='headlines_box'>Family Parties</h5>
                                <img src={require('../images/002.jpg')} className='pic-circle'/>
                                <button className='btn btn-flat black white-text btn-box'>Book Venues</button>                           
                            </div>
                            <div className='box-over center'>
                                <h5 className='headlines_box'>Socials !!!</h5>
                                <img src={require('../images/004.png')} className='pic-circle'/>
                                <button className='btn btn-flat black white-text btn-box'>Book Venues</button>                           
                            </div>
                            
                        </div>
                    </div>
                </div>
                <div className='section black app-box'>
                    <div className='row container'>
                        <div className='col s12 m4'>
                            <div className='app_img'>
                            <img src={require('../images/010.png')} className='pic-app'/>
                            </div>
                        </div>
                        <div className='col s12 m8'>
                            <h3 className='headlines_app'>Your Celebration,<br></br> Our Connection.</h3>
                            <h5 className='headlines_app'>Book top venues and caterers in a tap. Simplify planning, save time, and create unforgettable events. Download and celebrate!</h5>
                            <button className='btn btn-flat black white-text'>Download</button>
                            <br/>
                            <h5 className='headlines_app'>Supported By:</h5>
                            <img src={require('../images/011.png')} className='pic-s'/>
                        </div>
                    </div>
                </div>
                <div className='section'>
                    <div className='second_main center'>
                        <h2 className='s_main_head'>Why Us</h2>
                        <div className='row s_gap'>
                            <div className='col s12 m6'>
                                <div className='card_us valign-wrapper'>
                                    <div className='digit'>
                                        <h2 className='s_main_head'>100+</h2>
                                        <h6>Vendors</h6>
                                    </div>
                                    <div className='desc left-align'>
                                        <h6 className='s_main_desc'>Largest network of Vendors, Caterers and service providers to connect and collect quotes at a single click !!!</h6>
                                    </div>
                                </div>
                            </div>
                            <div className='col s12 m6'>
                            <div className='card_us valign-wrapper'>
                                    <div className='digit'>
                                        <h2 className='s_main_head'>Single</h2>
                                        <h6>One-Stop Solution</h6>
                                    </div>
                                    <div className='desc left-align'>
                                        <h6 className='s_main_desc'>From venue selection to catering, decor, and entertainment, find all essential services under one roof for a seamless event planning experience.</h6>
                                    </div>
                                </div>
                            </div>
                            <div className='col s12 m6'>
                            <div className='card_us valign-wrapper'>
                                    <div className='digit'>
                                        <h2 className='s_main_head'>A1</h2>
                                        <h6>Personalized Planning</h6>
                                    </div>
                                    <div className='desc left-align'>
                                        <h6 className='s_main_desc'>Tailored solutions for every event, offering customized packages that fit your style, budget, and preferences to make your occasion truly unique.</h6>
                                    </div>
                                </div>
                            </div>
                            <div className='col s12 m6'>
                            <div className='card_us valign-wrapper'>
                                    <div className='digit'>
                                        <h2 className='s_main_head'>50+</h2>
                                        <h6>Experienced Professionals</h6>
                                    </div>
                                    <div className='desc left-align'>
                                        <h6 className='s_main_desc'>Work with certified and highly experienced vendors who ensure top-notch quality and reliability to make your event stress-free and memorable.</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <footer class="page-footer black">
          <div class="container">
            <div class="row">
              <div class="col l6 s12">
                <h5 class="white-text headlines_app">airion</h5>
                <p class="grey-text text-lighten-4">Abhilasha Palace, Saguna more Patna.</p>
              </div>
              <div class="col l4 offset-l2 s12">
                <h5 class="white-text">Reach Us</h5>
                <ul>
                  <li><a class="grey-text text-lighten-3" href="#!">Vendor Registration</a></li>
                  <li><a class="grey-text text-lighten-3" href="#!">About</a></li>
                  <li><a class="grey-text text-lighten-3" href="#!">Complaints</a></li>
                  <li><a class="grey-text text-lighten-3" href="#!"></a></li>
                </ul>
              </div>
            </div>
          </div>
          <div class="footer-copyright">
            <div class="container">
            © 2014 Copyright Text
            <a class="grey-text text-lighten-4 right" href="#!">Designed by Ap</a>
            </div>
          </div>
        </footer>
            </div>
        </div>
    )
}